.history-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}
.history-card .icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s, color 0.3s; 
  cursor: pointer; 
}

.history-card .icon-container:hover {
  transform: rotate(45deg); 
  color: #2196F3; 
  background-color:#2196F3;
  border-radius: 50%;
}

.history-card .icon-container .MuiSvgIcon-root {
  font-size: 30px; 
}

.history-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-btn {
  background-color: #ffffff;
  color: rgb(3, 116, 255);
  border: none;
  padding: 20px 30px;
  margin: 0 10px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 0px;
  transition: background-color 0.3s ease;
}

.tab-btn.active {
  background-color: #007bff; 
  color: white; 
  border-bottom-color: #007bff; 
} 

.tab-btn:hover {
background-color: #0056b3; 
color: white; 

}
.history-content {
  display: flex;
  flex-direction: column;
}

.history-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  border: 1px solid #d4d3d3;
  border-radius: 0px;
  margin-bottom: 15px;
  background-color: white;
  transition: box-shadow 0.3s ease;
}

.history-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.icon-container {
  display: flex;
  align-items: center;
 
}

.history-info {
  flex: 1;
  margin-left: 15px;
}

.phone-number {
  font-size: 18px;
  font-weight: bold;
}

.date {
  color: #666;
  font-size: 14px;
}

.delete-button {
  background: none;
  border: none;
  cursor: pointer;
}

.delete-button:hover {
  opacity: 0.7;
}

@media (max-width: 600px) {
  .history-container {
    padding: 10px;
  }

  .history-card {
    padding: 10px;
  }

  .phone-number {
    font-size: 14px;
  }

  .date {
    font-size: 12px;
  }

  .delete-button img {
    width: 20px;
    height: 20px;
  }
}
