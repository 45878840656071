.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f8f9fa;
}

.login-form {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.login-form h2 {
    margin-bottom: 1rem;
}

.login-form form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.login-form input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    padding: 0.75rem;
    background: #FF7A00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}
