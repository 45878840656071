.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    transition: background-color 0.3s ease;
}

.navbar-menu ul li {
    color: black;
}

.navbar::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 2rem; 
    right: 2rem; 
    border-bottom: 0.1px solid #ffffff;
    transition: border-color 0.3s ease;
}

.navbar-brand {
    font-size: 1.5rem;
    font-weight: bold;
}

.menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.navbar-menu {
    list-style: none;
    display: flex;
    gap: 4rem;
}

.navbar-menu li a.active {
    position: relative;
}

.navbar-menu li a.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -7px;
    width: 100%;
    height: 2px;
    background: #FF7A00;
}

.navbar-menu li a {
    text-decoration: none;
}

.auth-links {
    display: flex;
    gap: 0.5rem;
}

.login, .register {
    padding: 0.5rem 1rem;
    border: none;
    cursor: pointer;
    border-radius: 2px;
    text-decoration: none;
}

.register {
    background: #FF7A00;
    color: #fff;
}

@media (max-width: 768px) {
    .navbar {
        padding: 1rem;
    }
    .navbar-menu {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: absolute;
        top: 80%;
        left: 0;
        right: 0;
        background: linear-gradient(103.59deg, #0A06E0 0%, #0085FF 120.96%);
        backdrop-filter: blur(10px);
        padding: 1rem 1rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0;
    }
    .navbar-menu.open {
        max-height: 300px;
        opacity: 1;
    }

    .menu-icon {
        display: block;
    }
    .login, .register {
        margin-left: 0;
    }
    .auth-links {
        display: none;
        flex-direction: column;
        gap: 0.3rem;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
        opacity: 0;
    }
    .auth-links.open {
        display: flex;
        max-height: 300px; 
        opacity: 1;
    }
}
.navbar-menu.disabled {
    pointer-events: none; 
    opacity: 0.5; 
}
